import { useAuth } from '../context/auth-context';
import { Navigate, useLocation } from "react-router-dom"

export const RequireAuth = ({ children }) => {
    const auth = localStorage.getItem('loggedUser')
    const location = useLocation();
    if (!auth) {
        return <Navigate to='/login' state={{ path: location.pathname }} />
    }
    return children;
}