import React from 'react'

const SPServices = ({ initials, children }) => {
    return (
        <section className={`${initials}-our-services md:py-10 md:px-20 p-8 text-white sp-services bg-theme-orange`}>
            <h1 className='our-services-heading section-heading text-center'>
                <span className='crumb'>
                    Our Services
                </span>
            </h1>
            {children}
        </section>
    )
}

export default SPServices