import { WhyChooseUsImages, WhatWeDoIcons } from "./ImageConstant";

export const WhatWeDo = [
    {
        id: 1,
        image: WhatWeDoIcons[0],
        heading: 'Operations Transformation',
        content: 'Our operations transformation solutions reimagine the workings of your organization to propel growth. We help you develop new capabilities and enhance current processes to help you realize the full potential of your operations.',
    },
    {
        id: 2,
        image: WhatWeDoIcons[2],
        heading: 'Analytics',
        content: 'We help you grow your business with quality data analysis and research. We provide you with relevant and accurate market research and analytics to help you generate valuable insights for you & your clients.',
    },
    {
        id: 3,
        image: WhatWeDoIcons[1],
        heading: 'Product Development',
        content: 'We partner with you to develop superior, innovative, and differentiated products for your needs. Our bespoke solutions are combined with in-depth domain knowledge and experiential learning.',
    },
    {
        id: 4,
        image: WhatWeDoIcons[3],
        heading: 'Cloud Transformation',
        content: 'Our Cloud Consulting team provides you with highly secure and scalable cloud solutions. We have solutions for all your needs – whether you are at an early stage of cloud consideration or running multi-cloud operations that require optimization.',
    },
];

export const WhyChooseUs = [
    {
        id: 1,
        image: WhyChooseUsImages[0],
        heading: 'Customized Solutions',
        content: 'We design highly adaptable and flexible custom software applications to streamline your internal business processes and goals.',
    },
    {
        id: 2,
        image: WhyChooseUsImages[1],
        heading: 'Certified Professionals',
        content: 'Our dedicated team of certified professionals ensures the highest satisfaction and an avant-garde user experience.',
    }, {
        id: 3,
        image: WhyChooseUsImages[2],
        heading: 'Access Vast Pool of Expertise',
        content: 'Partnering with Oaknst provides you with an opportunity to take advantage of our vast network of contacts.',
    },
]