import React, { useEffect, useRef, useState } from 'react'

import Hero from '../../components/Hero'
import SPBenefits from '../../components/servicesComponents/SPBenefits'
import SPServices from '../../components/servicesComponents/SPServices'
import OurClientsSection from '../../components/OurClientsSection'

import { CT_Benefits, CT_Services } from '../../constants/servicesConstants'

const CloudTransformation = () => {

    const CT_Clients = ['Young Organizations', 'Family-Owned Businesses'];
    const initials = 'ct';
    const overViewText = 'Oaknst provides you with highly secure and scalable cloud solutions that guarantee the highest level of data security. We have solutions for your needs – whether you are at an early age of deploying your migration strategy or want to transform your existing cloud to make it comparable with the best.';

    const serviceRef = useRef(null);
    const [showServiceAni, setShowServiceAni] = useState(false);

    const clientRef = useRef(null);
    const [showClientAni, setShowClientAni] = useState(false);

    const benefitRef = useRef(null);
    const [showBenefitAni, setShowBenefitAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                let id = entry.target.id;
                if (entry.isIntersecting) {
                    switch (id) {
                        case 'service': {
                            setShowServiceAni(true);
                            break;
                        }
                        case 'client': {
                            setShowClientAni(true);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(true);
                            break;
                        }
                        default: break;
                    }
                } else {
                    switch (id) {
                        case 'client': {
                            setShowClientAni(false);
                            break
                        }
                        case 'service': {
                            setShowServiceAni(false);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(false);
                            break;
                        }
                        default: break;
                    }
                }
            });
        });
        observer.observe(benefitRef.current);
        observer.observe(serviceRef.current);
        observer.observe(clientRef.current);
    }, []);

    return (
        <div className='ct-section'>
            <section className='ct-hero-section'>
                <Hero
                    heading={"Cloud Transformation"}
                    subHeading={"Unlock great business value and opportunities without giving up control of your data."}
                    color={null}
                />
            </section>

            <SPBenefits initials={initials} overViewText={overViewText}>
                <div className={`fade ct-benefits-container grid md:grid-cols-4 gap-10 pt-8`} ref={benefitRef} id='benefit'>
                    {CT_Benefits.map(benefit =>
                        <div key={benefit.id}
                            className='text-center'>
                            <img src={`/assets/services/CT/${benefit.image}`} alt={benefit.heading} className='mx-auto' />
                            <h3 className='sub-heading pb-2 text-center'>{benefit.heading}</h3>
                            <p className='sub-heading-content'>{benefit.content}</p>
                        </div>
                    )}
                </div>
            </SPBenefits>

            <SPServices initials={initials}>
                <div className='ct-our-services-container grid md:grid-cols-2 gap-x-20 gap-y-10 pt-10 md:px-10' ref={serviceRef} id='service'>
                    {CT_Services.map((service) =>
                        <div key={service.id}
                            className={`${showServiceAni && 'ct-service'} text-center relative opacity-0`}>
                            <img className='mx-auto' src={`/assets/services/CT${service.src}`} alt='i' />
                            <h3 className='ct-service-heading sub-heading text-white py-2'>{service.heading}</h3>
                            <p className='ct-service-desc sub-heading-content md:px-8 h-full'>{service.content}</p>
                        </div>
                    )}
                </div>
            </SPServices>

            <OurClientsSection>
                <div className={`${showClientAni && 'fadeInDown'} relative`} ref={clientRef} id='client'>
                    <div className='our-clients-container flex md:flex-row flex-col justify-evenly md:gap-y-10 pt-8'>
                        {CT_Clients.map((client, index) =>
                            <div className='ct-client flex md:items-start items-center' key={index}>
                                <img src='/assets/services/clients.svg' alt={''} />
                                <div className='serive-content p-4'>
                                    <h3 className='client-heading sub-heading pb-5'>{client}</h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='ct-client flex items-start md:justify-center'>
                        <img src='/assets/services/clients.svg' alt={''} />
                        <div className='serive-content p-4'>
                            <h3 className='client-heading sub-heading pb-5'>Small & Mid-Size Organizations</h3>
                        </div>
                    </div>
                </div>
            </OurClientsSection>
        </div>
    )
}

export default CloudTransformation