import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import Spinner from '../../components/Spinner'

import { apiUrl } from '../../api'

import './adminContacts.css'

function AdminContacts() {

    const [contacts, setContacts] = useState([])
    const [contactIndex, setContactIndex] = useState(0)
    const [flag, setFlag] = useState(true)

    const getContacts = useCallback(async () => {
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { data } = await axios.get(`${apiUrl}/getContacts/${contactIndex}`, config)
        setContacts([...contacts, ...data])
        setContactIndex(contactIndex + 20)
        console.log(contactIndex)
    }, [contacts, contactIndex]);

    const loadMore = () => {
        getContacts()
    }

    useEffect(() => {
        if (contacts.length === 0)
            getContacts()

        if (contacts.length < contactIndex) {
            setFlag(false)
        }
    }, [contacts.length, getContacts, contactIndex]);

    return (
        <div className='admincontactcontainer' >
            <div className='w-full m-0 flex justify-center' >
                <div className='w-1/12 contact-headings'>
                    S.No.
                </div>
                <div className='w-1/12 contact-headings'>
                    First Name
                </div>
                <div className='w-1/12 contact-headings'>
                    Last Name
                </div>
                <div className='w-1/12 contact-headings'>
                    Company
                </div>
                <div className='w-1/6 contact-headings'>
                    Email
                </div>
                <div className='w-1/12 contact-headings'>
                    Phone
                </div>
                <div className='w-1/12 contact-headings'>
                    Country
                </div>
                <div className='w-1/4 contact-headings' style={{ borderRight: "0" }}>
                    Message
                </div>
            </div>
            {
                contacts.length ?
                    contacts.map((contact, index) => {
                        return (
                            <div key={index}
                                className='flex w-full mx-0 flex justify-center' >
                                <div className='w-1/12 contact-info'>
                                    {index + 1}
                                </div>
                                <div className='w-1/12 contact-info'>
                                    {contact.firstName}
                                </div>
                                <div className='w-1/12 contact-info'>
                                    {contact.lastName}
                                </div>
                                <div className='w-1/12 contact-info'>
                                    {contact.company}
                                </div>
                                <div className='w-1/6 contact-info'>
                                    {contact.email}
                                </div>
                                <div className='w-1/12 contact-info'>
                                    {contact.phone}
                                </div>
                                <div className='w-1/12 contact-info'>
                                    {contact.country}
                                </div>
                                <div className={`w-1/4 contact-info ${contact.message.length > 20 ? 'message' : null}`} style={{ borderRight: "0" }}>
                                    {contact.message}
                                </div>
                            </div>
                        )
                    }) : <div className='flex justify-center al my-5' style={{ height: `662.26px`, paddingTop: "250px" }}><Spinner /></div>
            }
            {
                flag ?
                    <div className='flex justify-center ' style={{ cursor: "pointer", margin: "80px 0 30px" }}>
                        <div onClick={loadMore} id="load-more">
                            <div>Load More</div>
                        </div>
                    </div>
                    : <div className='flex justify-center' style={{ margin: "30px 0" }}>--------------------End of Page--------------------</div>
            }
        </div>
    )
}

export default AdminContacts