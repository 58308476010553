import React, { useEffect, useRef, useState } from 'react'

import Hero from '../../components/Hero'
import OurClientsSection from '../../components/OurClientsSection'
import SPBenefits from '../../components/servicesComponents/SPBenefits';
import SPServices from '../../components/servicesComponents/SPServices';
import { PD_Benefits, PD_Services } from '../../constants/servicesConstants';

const ProductDevelopment = () => {

    const initials = 'pd';
    const overViewText = 'We partner with you to develop superior, innovative, and differentiated products exclusive to your needs. Our bespoke solutions are combined with in-depth domain knowledge and experiential learning.';

    const PD_Clients = ['Young Organizations', 'Family-Owned Businesses'];

    const serviceRef = useRef(null);
    const [showServiceAni, setShowServiceAni] = useState(false);

    const clientRef = useRef(null);
    const [showClientAni, setShowClientAni] = useState(false);

    const benefitRef = useRef(null);
    const [showBenefitAni, setShowBenefitAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                let id = entry.target.id;
                if (entry.isIntersecting) {
                    switch (id) {
                        case 'service': {
                            setShowServiceAni(true);
                            break;
                        }
                        case 'client': {
                            setShowClientAni(true);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(true);
                            break;
                        }
                        default: break;
                    }
                } else {
                    switch (id) {
                        case 'client': {
                            setShowClientAni(false);
                            break
                        }
                        case 'service': {
                            setShowServiceAni(false);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(false);
                            break;
                        }
                        default: break;
                    }
                }
            });
        });
        observer.observe(benefitRef.current);
        observer.observe(serviceRef.current);
        observer.observe(clientRef.current);
    }, []);


    return (
        <div className='pd-section'>
            <section className='pd-hero-section'>
                <Hero
                    heading={"Product Development"}
                    subHeading={"Build high-performing product solutions with us to deliver your vision."}
                    color={""}
                />
            </section>

            <SPBenefits initials={initials} overViewText={overViewText}>
                <div className={`${showBenefitAni && 'fade'} pd-benefits-container flex flex-col md:flex-row md:flex-wrap justify-center gap-14 pt-10 gap-x-24`} ref={benefitRef} id='benefit'>
                    {PD_Benefits.map(benefit =>
                        <div key={benefit.id}
                            className='text-center basis-1/4'>
                            <img src={`/assets/services/PD${benefit.src}`} alt={''} className='mx-auto' />
                            <h3 className='sub-heading py-1 text-center'>
                                {benefit.heading}
                            </h3>
                            <p className='sub-heading-content'>{benefit.content}</p>
                        </div>
                    )}
                </div>
            </SPBenefits>

            <SPServices initials={initials}>
                <div className={`pd-services-container flex flex-col md:flex-row md:flex-wrap justify-center gap-24 pt-10 gap-y-14`} ref={serviceRef} id='service'>
                    {PD_Services.map(service =>
                        <div key={service.id}
                            className={`${showServiceAni && 'pd-service fade'} text-center basis-1/4`}>
                            <img className='mx-auto' src={`/assets/services/PD${service.src}`} alt='i' />
                            <h3 className='sub-heading'>
                                {service.heading}
                            </h3>
                            <p className='sub-heading-content pt-2'>{service.content}</p>
                        </div>
                    )}
                </div>
            </SPServices>

            <OurClientsSection>
                <div className={`${showClientAni && 'fadeInDown'}`} ref={clientRef} id='client'>
                    <div className='our-clients-container flex md:flex-row flex-col justify-evenly md:gap-y-10 pt-8'>
                        {PD_Clients.map((client, index) =>
                            <div className='pd-client flex md:items-start items-center' key={index}>
                                <img src='/assets/services/clients.svg' alt={''} />
                                <div className='serive-content p-4'>
                                    <h3 className='client-heading sub-heading pb-5'>{client}</h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='pd-client flex items-start justify-center'>
                        <img src='/assets/services/clients.svg' alt={''} />
                        <div className='serive-content p-4'>
                            <h3 className='client-heading sub-heading pb-5'>Small & Mid-Size Organizations</h3>
                        </div>
                    </div>
                </div>
            </OurClientsSection>
        </div>
    )
}

export default ProductDevelopment