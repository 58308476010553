const NavLinks = [
    {
        id: 1,
        name: 'Home',
        to: '/',
        dropdown: null
    },
    {
        id: 2,
        name: 'Services',
        to: '#',
        dropdown: [
            {
                id: 21,
                name: 'Operations Transformation',
                to: '/operationsTransformation',
            },
            {
                id: 22,
                name: 'Analytics',
                to: '/analytics',
            },
            {
                id: 23,
                name: 'Product Development',
                to: '/productDevelopment',
            },
            {
                id: 24,
                name: 'Cloud Transformation',
                to: '/cloudTransformation',
            }
        ]
    },
    {
        id: 3,
        name: 'Products',
        to: 'https://tenie.co/',
        dropdown: null,
    },
    {
        id: 4,
        name: 'Resources',
        to: '/resources',
        dropdown: null,
    },
    {
        id: 5,
        name: 'Contact',
        to: '/contact',
        dropdown: null,
    },
];

export { NavLinks };