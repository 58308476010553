import React from 'react'

const SPBenefits = ({ initials, overViewText, children }) => {
    return (
        <section className={`${initials} sp-benefit md:p-12 md:px-20 p-8`}>
            <h1 className='text-center'>
                {overViewText}
            </h1>
            <div className={`${initials}-benefits mt-10`}>
                <h1 className='benefits-heading section-heading text-center'>
                    <span className='crumb'>
                        Benefits
                    </span>
                </h1>
                {children}
            </div>
        </section>
    )
}

export default SPBenefits;