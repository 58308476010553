import React from 'react'
import { Link } from 'react-router-dom';

const Hero = ({ heading, subHeading, svgImage, color }) => {
    return (
        <div className='grid md:grid-cols-2 grid-rows-1 md:p-20 p-10 items-center hero h-80 mb-4'>
            <div className={`fadeInDown hero-left text-${color ? "theme-" + color : "white"}`}>
                <h1 className='hero-heading pb-2 text-left'>{heading}</h1>
                <h3 className='text-lg md:text-xl font-normal'>{subHeading}</h3>
                <Link to={'/contact'}>
                    <button className='get-in-touch-btn px-12 py-2 mt-8'>
                        Get In Touch
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Hero