// Blog's actions
export const CREATE_BLOG = 'CREATE_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const FETCH_ALL_BLOGS = 'FETCH_ALL_BLOGS';
export const LOAD_MORE = 'LOAD_MORE';

// User's actions
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_ALL_USER = 'FETCH_ALL_USER';

// Flash's actions
export const FLASH_SUCCESS = 'FLASH_MESSAGE';
export const FLASH_ERROR = 'FLASH_ERROR';
export const RESET_MESSAGE = 'RESET_MESSAGE';
