import React from 'react'

const OurClientsSection = ({ children }) => {
	return (
		<div className='our-clients py-6 px-12'>
			<h1 className='our-clients-heading section-heading text-center'>
				<span className='crumb'>
					Our Clients
				</span>
			</h1>
			{children}
		</div>
	)
}

export default OurClientsSection