import { useState, createContext, useContext, useEffect } from "react";

export const AuthContext = createContext();

export const Authprovider = ({ children }) => {
    const [loggedUser, setLoggedUser] = useState(null);

    // Function to set the logged-in user and store it in local storage
    const logIn = (user) => {
        setLoggedUser(user);
        localStorage.setItem("loggedUser", JSON.stringify(user));
    };

    // Function to log out the user and remove from local storage
    const logOut = () => {
        setLoggedUser(null);
        localStorage.removeItem("loggedUser");
    };

    // Initialize state from local storage on component mount
    useEffect(() => {
        const storedUser = localStorage.getItem("loggedUser");
        if (storedUser) {
            setLoggedUser(JSON.parse(storedUser));
        }
    }, []);

    return (
        <AuthContext.Provider value={{ loggedUser, logIn, logOut }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
}
