import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import RichTextEditor from '../../components/RichTextEditor'
import { createBlog } from '../../actions/blogs'
import { useAuth } from '../../context/auth-context'
import toast, { Toaster } from 'react-hot-toast';
function CreateBlog() {

    const [blogBody, setBlogBody] = useState("")
    const [blogData, setBlogData] = useState({ title: '', image: '', category: 'Operations', summary: '', body: '' })
    const [formErrors, setFormErrors] = useState({})
    const auth = useAuth()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormErrors(blogValidation(blogData))
    }

    const blogValidation = (blog) => {
        let errors = {}

        if (blog.title.length === 0) {
            errors = { ...errors, title: 'Title is empty!' }
        }
        if (blog.image.length === 0) {
            errors = { ...errors, image: 'Image is not selected!' }
        }
        if (blog.category.length === 0) {
            errors = { ...errors, category: 'Category is not selected!' }
        }
        if (blog.summary.length === 0) {
            errors = { ...errors, summary: 'Summary is empty!' }
        }
        if (blog.body.length === 0) {
            errors = { ...errors, body: 'Blog content is empty!' }
        }

        if (Object.keys(errors).length === 0) {
            submitBlog()
        }

        return errors
    }

    console.log(blogData);
    const submitBlog = () => {
        const formData = new FormData();
        toast.success('Your blog is being processed please wait...')
        formData.append('title', blogData.title)
        formData.append('image', blogData.image)
        formData.append('category', blogData.category)
        formData.append('summary', blogData.summary)
        formData.append('body', blogData.body)
        formData.append('author', auth.loggedUser._id)
        toast.promise(dispatch(createBlog(formData)), {
            loading: 'Creating blog...',
            success: (msg) => {
                return msg;
            },
            error: (err) => {
                return err;
            },
        });
        navigate('/')
    }

    useEffect(() => {
        setBlogData({ ...blogData, body: blogBody });
    }, [blogBody]);

    return (
        <div className='blog-content mb-5' style={{ overflow: "auto" }}>
            <Toaster />
            <div className='flex justify-center'>
                <div className='w-full md:w-3/4'>
                    <div className='flex flex-col justify-top items-top m-5' style={{ height: "90vh" }}>
                        <div className='flex items-center bg-gradient-to-r from-theme-orange to-white'>
                            <h2 className='m-1 ml-2' style={{ color: "white" }}>
                                Create Blog
                            </h2>
                        </div>
                        <form onSubmit={handleSubmit} encType='multipart/form-data' validate="true">
                            <div>
                                <div className='w-full my-5'>
                                    <div className='w-1/4'>
                                        Choose Banner:
                                    </div>
                                    <div className="w-3/4">
                                        <input type="file" onChange={(e) => setBlogData({ ...blogData, image: e.target.files[0] })} />
                                        <div className="blog-form-error">{formErrors.image ? formErrors.image : null}</div>
                                    </div>
                                </div>
                                <div className='w-full my-5'>
                                    <div className='w-1/4'>
                                        Enter Title:
                                    </div>
                                    <div className='w-3/4'>
                                        <input className='form-control' type="text" placeholder='Enter title' onChange={(e) => setBlogData({ ...blogData, title: e.target.value })} />
                                        <div className="blog-form-error">{formErrors.title ? formErrors.title : null}</div>
                                    </div>
                                </div>
                                <div className='w-full my-5'>
                                    <div className='w-1/4'>
                                        Choose Category:
                                    </div>
                                    <div className='w-3/4'>
                                        <select onChange={(e) => setBlogData({ ...blogData, category: e.target.value })} className="form-select" aria-label="Default select example">
                                            <option value="none" disabled>Open this select menu</option>
                                            <option value="Operations">Operations</option>
                                            <option value="Analytics">Analytics</option>
                                            <option value="Product">Product</option>
                                            <option value="Cloud">Cloud</option>
                                        </select>
                                        <div className="blog-form-error">{formErrors.category ? formErrors.category : null}</div>
                                    </div>
                                </div>
                                <div className='w-full my-5'>
                                    <div className='w-1/4'>
                                        Write Summary:
                                    </div>
                                    <div className='w-3/4'>
                                        <textarea className='form-control' placeholder='Write summary' onChange={(e) => setBlogData({ ...blogData, summary: e.target.value })} />
                                        <div className="blog-form-error">{formErrors.summary ? formErrors.summary : null}</div>
                                    </div>
                                </div>
                                <div className='my-3'>
                                    <label className='mb-3'>
                                        Write Blog Content:
                                    </label>
                                    <RichTextEditor className="editorPanel" initialValue={""} setBlogBody={setBlogBody} />
                                    <div className="blog-form-error">{formErrors.body ? formErrors.body : null}</div>
                                </div>
                                <div className='my-5'>
                                    <button type='submit' className='submit-btn bg-theme-orange hover:bg-theme-ohover text-white border-0 px-5 py-2 text-xl'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateBlog