// import NavLogo from '../../public/assets/navLogo.png';

const Navlogo = {
    src: "/assets/navLogo.png",
    alt: "company-logo"
}

const CarouselImages = [
    {
        src: "/assets/carousel1.png",
        alt: "bannerimage1"
    },
];

const WhyChooseUsImages = [
    {
        src: "/assets/CustomizedSolutions.png",
        alt: "Customized Solutions"
    },
    {
        src: "/assets/CertifiedProfessionals.png",
        alt: "Certified Professionals",
    },
    {
        src: "/assets/AccessVastPoolofExpertise.png",
        alt: "Access Vast Pool of Expertise",
    }
];

const WhatWeDoIcons = [
    {
        src: "/assets/OT.svg",
        alt: "Operation Transformation"
    },
    {
        src: "/assets/PD.svg",
        alt: "Product Development"
    },
    {
        src: "/assets/Analytics.svg",
        alt: "Analytics"
    },
    {
        src: "/assets/CT.svg",
        alt: "Cloud Transformation"
    },
]

export { Navlogo, CarouselImages, WhyChooseUsImages, WhatWeDoIcons };