import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BiChevronDown } from 'react-icons/bi';
import axios from 'axios';

import { apiUrl } from '../api/index';
import { useAuth } from '../context/auth-context';
import { FLASH_SUCCESS, FLASH_ERROR } from '../constants/actionTypes';
import { Navlogo } from '../constants/ImageConstant';
import { NavLinks } from '../constants/NavLinks';

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useAuth();

    const [toggle, setToggle] = useState(false);

    const handleLogout = async () => {
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`${apiUrl}/logout`, config)

        if (data.type === 'success')
            auth.logOut()

        if (data.type === "success") {
            dispatch({ type: FLASH_SUCCESS, payload: data.message })
        }
        else
            dispatch({ type: FLASH_ERROR, payload: data.message })

        navigate('/', { replace: true })
        toggleNavbar();
    }

    function toggleNavbar() {
        setToggle(prev => !prev);
    }

    return (
        <nav className=''>
            <div className='nav-container flex justify-between items-stretch my-2'>
                {/* <div className="logo-container cover"> */}
                <div className='flex items-center'>
                    <Link to={'/'}>
                        <img className='w-[65%] h-[90%] align-self-start' src={Navlogo.src} alt={Navlogo.alt} />
                    </Link>
                </div>
                {/* </div> */}

                <div className={`links-container grid`}>
                    <ul className={`ul-links-container md:flex text-sm font-normal items-center hidden`}>
                        {NavLinks.map(singleNav =>
                            <li key={singleNav.id} className={`nav-link ${singleNav.dropdown && 'services'}`}>
                                <Link to={`${singleNav.to}`}
                                    className='transition-all ease-in-out relative'
                                >{singleNav.name}
                                    {singleNav.dropdown &&
                                        <BiChevronDown className='inline ml-1.5' />
                                    }
                                </Link>
                                {singleNav.dropdown &&
                                    <ul className='nav-dropdown'>
                                        {singleNav.dropdown.map(service =>
                                            <li key={service.id} className='nav-dropdown-item'>
                                                <Link to={`services${service.to}`}>{service.name}</Link>
                                            </li>
                                        )}
                                    </ul>
                                }
                            </li>
                        )}
                        {
                            (auth.loggedUser && (process.env.REACT_APP_ADMIN === auth.loggedUser._id)) ?
                                <li className="nav-link admin-link">
                                    <Link className='transition-all ease-in-out relative'>
                                        Admin
                                        <BiChevronDown className='inline' />
                                    </Link>
                                    <div className='nav-dropdown'>
                                        <Link to='/admin/blogs'>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                Blogs
                                            </div>
                                        </Link>
                                        <Link to='/admin/users'>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                Users
                                            </div>
                                        </Link>
                                        <Link to='/admin/contacts'>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                Contacts
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                : null
                        }
                        {
                            auth.loggedUser ?
                                <>
                                    <li className="nav-link blogs">
                                        <Link className='transition-all ease-in-out relative'>
                                            <div>Blogs
                                                <BiChevronDown className='inline' />
                                            </div>
                                        </Link>
                                        <div className='nav-dropdown'>
                                            {/* <div className='header-drop-down-blur' /> */}
                                            <Link to="/userblogs">
                                                <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                    My Blogs
                                                </div>
                                            </Link>
                                            <Link to='/resources/createblog'>
                                                <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                    Create Blog
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="nav-link">
                                        <a href="#"
                                            className='transition-all ease-in-out relative'
                                            onClick={handleLogout}>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                Logout
                                            </div>
                                        </a>
                                    </li>
                                </>
                                :
                                <li className="nav-link">
                                    <Link to="/login" className='header-links header-links-2 transition-all ease-in-out relative'>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            Login
                                        </div>
                                    </Link>
                                </li>
                        }
                    </ul>

                    {/* hamburger icon */}
                    <div className="flex justify-end text-black px-2 md:hidden">
                        <button type="button" className="text-gray-500 hover:text-white focus:outline-none focus:text-white transition duration-150 ease-in-out" aria-label="Toggle menu" onClick={toggleNavbar}>
                            <svg className="h-6 w-6" viewBox="0 0 24 24">
                                <path v-else="true" fillRule="evenodd" clipRule="evenodd" d="M4 6h16v2H4V6zM4 11h16v2H4v-2zM4 16h16v2H4v-2z" />
                            </svg>
                        </button>
                    </div>

                </div >
            </div>

            {toggle &&
                <ul className={`ul-links-container flex flex-wrap text-sm font-normal items-center md:hidden`}>
                    {NavLinks.map(singleNav =>
                        <li key={singleNav.id} className={`nav-link ${singleNav.dropdown && 'services'}`}>
                            <Link to={singleNav.to} onClick={singleNav.id === 2 ? null : toggleNavbar}
                                className='transition-all ease-in-out relative'
                            >{singleNav.name}
                                {singleNav.dropdown &&
                                    <BiChevronDown className='inline ml-1.5' />
                                }
                            </Link>
                            {singleNav.dropdown &&
                                <ul className='nav-dropdown'>
                                    {singleNav.dropdown.map(service =>
                                        <li key={service.id} className='nav-dropdown-item'>
                                            <Link to={`services${service.to}`} onClick={toggleNavbar}>{service.name}</Link>
                                        </li>
                                    )}
                                </ul>
                            }
                        </li>
                    )}
                    {
                        (auth.loggedUser && (process.env.REACT_APP_ADMIN !== auth.loggedUser._id)) ?
                            <li className="nav-link admin-link">
                                <Link className='transition-all ease-in-out relative' onClick={toggleNavbar}>
                                    Admin
                                    <BiChevronDown className='inline' />
                                </Link>
                                <div className='nav-dropdown'>
                                    <Link to='/admin/blogs' onClick={toggleNavbar}>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                            Blogs
                                        </div>
                                    </Link>
                                    <Link to='/admin/users' onClick={toggleNavbar}>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                            Users
                                        </div>
                                    </Link>
                                    <Link to='/admin/contacts' onClick={toggleNavbar}>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                            Contacts
                                        </div>
                                    </Link>
                                </div>
                            </li>
                            : null
                    }
                    {
                        auth.loggedUser ?
                            <>
                                <li className="nav-link blogs">
                                    <Link className='transition-all ease-in-out relative' onClick={toggleNavbar}>
                                        <div>Blogs
                                            <BiChevronDown className='inline' />
                                        </div>
                                    </Link>
                                    <div className='nav-dropdown'>
                                        {/* <div className='header-drop-down-blur' /> */}
                                        <Link to="/userblogs" onClick={toggleNavbar}>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                My Blogs
                                            </div>
                                        </Link>
                                        <Link to='/resources/createblog' onClick={toggleNavbar}>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                Create Blog
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-link">
                                    <a href="#"
                                        className='transition-all ease-in-out relative'
                                        onClick={handleLogout}>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            Logout
                                        </div>
                                    </a>
                                </li>
                            </>
                            :
                            <li className="nav-link">
                                <Link to="/login" className='header-links header-links-2 transition-all ease-in-out relative' onClick={toggleNavbar}>
                                    <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        Login
                                    </div>
                                </Link>
                            </li>
                    }
                </ul>
            }
        </nav >
    )
}

export default Navbar