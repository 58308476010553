const VectorSvg = '/assets/services/OT/mainVector.svg';

const Benefits = [
    {
        id: 1,
        src: '/assets/services/OT/Benefits1.svg',
        alt: 'Reduce Operating Expenses',
        heading: 'Reduce Operating Expenses',
        content: 'Save significant time and money on operating expenses by transforming your operations with the help of process changes and technology'
    },
    {
        id: 2,
        src: '/assets/services/OT/Benefits2.svg',
        alt: 'Increase Customer Loyalty',
        heading: 'Increase Customer Loyalty',
        content: 'Transformative processes allow you to be more responsive to customer feedback and build a loyal customer base.'
    },
    {
        id: 3,
        src: '/assets/services/OT/Benefits3.svg',
        alt: 'Gain Market Share',
        heading: 'Gain Market Share',
        content: 'Meet & exceed customer expectations with agility and build a strong brand reputation that guarantees repeat business.'
    }
];

const OurServices = [
    {
        id: 1,
        src: '/assets/services/OT/services1.svg',
        alt: 'Operations Diagnostic',
        heading: 'Operations Diagnostic',
        content: 'Assess current operations system with the help of Industry KPIs and data-driven process mining and analyses.',
    },
    {
        id: 2,
        src: '/assets/services/OT/services3.svg',
        alt: 'Roadmap to Future',
        heading: 'Roadmap to Future',
        content: 'Develop a long-term operation structure that’s integrated with the business aspirations.',
    },
    {
        id: 3,
        src: '/assets/services/OT/services2.svg',
        alt: 'Process Upgrades',
        heading: 'Process Upgrades',
        content: "Develop corporate processes across different facets – business strategy, talent, finance & IT to support an integrated operation's goal.",
    },
    {
        id: 4,
        src: '/assets/services/OT/services4.svg',
        alt: 'Implementation',
        heading: 'Implementation',
        content: 'Plan & implement the changes with continuous support & refinements as required by the changing business scenarios.',
    }
];

const PD_Benefits = [
    {
        id: 1,
        src: '/IdeaGeneration.png',
        heading: 'Idea Generation',
        content: 'We collaborate with you to develop ideas and concepts that shape your business strategy.',
    },
    {
        id: 2,
        src: '/ConceptDev.png',
        heading: 'Concept Development',
        content: 'We identify market trends and opportunities to anticipate consumer behavior to build product solutions that address all your needs.',
    },
    {
        id: 3,
        src: '/ConceptTesting.png',
        heading: 'Concept Testing',
        content: 'We collaborate with you to develop ideas and concepts that shape your business strategy.',
    },
    {
        id: 4,
        src: '/UserExp.png',
        heading: 'User Experience',
        content: 'Our product solutions are focused on creating the best experience for our users.',
    },
    {
        id: 5,
        src: '/Scalability.png',
        heading: 'Scalability',
        content: 'Our scalable solutions mould to your unique business needs to help you grow your business and team.',
    },
];

const PD_Services = [
    {
        id: 1,
        src: '/erp.png',
        heading: 'ERP',
        content: 'We build a customized ERP system to bolster workflow efficiencies through integrations & automation.',
    },
    {
        id: 2,
        src: '/e-commerce.png',
        heading: 'E-commerce',
        content: 'We build a fully functional customized e-commerce system that meets your diverse business needs.',
    },
    {
        id: 3,
        src: '/legacy-applications.png',
        heading: 'Legacy Applications',
        content: 'We redesign, modernize, and cost-effectively enhance legacy applications for you.',
    },
    {
        id: 4,
        src: '/custom-applications.png',
        heading: 'Custom Applications',
        content: 'Our bespoke application services allow you to create cross-platform solutions for your industry regardless of their size, scale, and complexity.',
    },
    {
        id: 5,
        src: '/new-products.png',
        heading: 'New Products',
        content: 'We design and develop customized products that allow you to transform your ideas into successful tangible products, encompassing all your needs.',
    },
];

const Analytics_Benefits = [
    {
        id: 1,
        src: '/long-term-benefits.png',
        heading: 'Long-term Benefits',
        content: ' Analytics help you generate lasting results by understanding patterns and trends. These patterns could be related to your customers, team, or the strategy you are employing to drive results.',
    },
    {
        id: 2,
        src: '/reduce-costs.png',
        heading: 'Reduce Costs',
        content: ' Affordability and cost-effectiveness are two of the major aspects of analytics. Our handy tools and applications can help you cut down on operational costs and arrive at actionable solutions quicker.',
    },
    {
        id: 3,
        src: '/real-time-updates.png',
        heading: 'Real-time Updates',
        content: ' Analytics and research help you formulate a fool proof strategy and drive tangible results by giving you access to real-time analytics.',
    },
    {
        id: 4,
        src: '/understanding-customers.png',
        heading: 'Understanding Customers',
        content: 'Analytics help you make faster conversions and generate leads that genuinely care about your brand. You can give them a better customer experience to help retain them longer.',
    },
];

const Analytics_Services = ['/assets/services/Analytics/BusinessIntelligence.svg', '/assets/services/Analytics/DataVisualization.svg', '/assets/services/Analytics/AdvancedAnalytics.svg']

const CT_Benefits = [
    {
        id: 1,
        heading: 'Scalability',
        content: 'Our scalable cloud solutions help you cope with a large workload while maintaining effectiveness. These will allow your business to grow without having to invest in expensive changes in your IT architecture.',
        image: 'Scalability.svg',
    },
    {
        id: 2,
        heading: 'Agility',
        content: 'Our solutions provide greater flexibility and business continuity that allows you to scale up and down quickly basis on your business requirements.',
        image: 'Agility.svg',
    },
    {
        id: 3,
        heading: 'Security',
        content: 'Take advantage of cloud security technologies such as data encryption and two-factor authentication for greater privacy than you would have when relying on your equipment or servers.',
        image: 'Security.svg',
    },
    {
        id: 4,
        heading: 'Accessibility',
        content: 'Our cloud solutions are accessible to you and your employees anywhere, with any device. Anyone can log in to their account and access the updated information from anywhere.',
        image: 'Accessibility.svg',
    },
    {
        id: 5,
        heading: 'Availability',
        content: 'Our solutions offer great availability that ensures the continual function of the cloud infrastructure with little downtime or outages.',
        image: 'Availability.svg',
    },
    {
        id: 6,
        heading: 'Disaster Recovery',
        content: 'We ensure that your data backups are centralized in data centers, removing the need for individual users or teams to maintain their backups onsite or offsite. It lowers the risk of data loss should any backup fail or be destroyed by a disaster.',
        image: 'DisasterRecovery.svg',
    },
    {
        id: 7,
        heading: 'Cost Effective',
        content: 'Our flexible pricing allows you to test the applications before investing in them. Our solutions don’t require expensive hardware or local data center maintenance.',
        image: 'CostEffective.svg',
    },
    {
        id: 8,
        heading: 'Automatic Updates',
        content: 'With our solutions, you always have access to the latest updates and versions of the software so that you don’t have to wait for long periods for the next update.',
        image: 'AutomaticUpdates.svg',
    },
];

const CT_Services = [
    {
        id: 1,
        src: '/strategy.png',
        heading: 'Cloud Strategy',
        content: 'We partner with you to help build your cloud strategy basis your needs. We start with a diagnostic assessment of your systems and business requirements and then present you with a bouquet of options to help you achieve scale with the lowest upfront investment. ',
    },
    {
        id: 2,
        heading: 'Cloud App Development',
        src: '/development.png',
        content: 'We implement highly scalable and cost-efficient cloud application solutions that ensure operational efficiency and faster product development on the cloud.',
    },
    {
        id: 3,
        heading: 'Cloud Migration',
        src: '/migration.png',
        content: 'Our experts work with your team to implement the cloud strategy by migrating – databases, applications, websites, IT processes, etc. – into the cloud). Our solutions make the migration process highly secure & cost-effective. We ensure you always have access to your business-critical applications while migrating. ',
    },
    {
        id: 4,
        heading: 'Cloud Security & Risk',
        src: '/risk.png',
        content: 'Our security specialists build custom security integration solutions to secure your data and critical business. We provide a comprehensive security strategy to monitor and detect threats. Our deployment solutions take care of your security concerns – from data protection to risk assessment to security monitoring.',
    },
];

export { VectorSvg, Benefits, OurServices, PD_Benefits, PD_Services, Analytics_Benefits, Analytics_Services, CT_Benefits, CT_Services };