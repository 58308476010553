import React, { useEffect, useRef, useState } from 'react'

import Hero from '../../components/Hero';
import OurClientsSection from '../../components/OurClientsSection';
import SPBenefits from '../../components/servicesComponents/SPBenefits';

import { Analytics_Benefits, Analytics_Services } from '../../constants/servicesConstants';
import SPServices from '../../components/servicesComponents/SPServices';

const Analytics = () => {

    const initials = 'analytics';
    const overViewText = 'We help you grow your business with quality data analysis and research. We provide you with relevant and accurate market research and analytics to help you generate valuable analytics reports and insights.';
    const Analytics_Clients = ['Mid-Size Organizations', 'Domestic Enterprises'];

    const serviceRef = useRef(null);
    const [showServiceAni, setShowServiceAni] = useState(false);

    const clientRef = useRef(null);
    const [showClientAni, setShowClientAni] = useState(false);

    const benefitRef = useRef(null);
    const [showBenefitAni, setShowBenefitAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                let id = entry.target.id;
                if (entry.isIntersecting) {
                    switch (id) {
                        case 'service': {
                            setShowServiceAni(true);
                            break;
                        }
                        case 'client': {
                            setShowClientAni(true);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(true);
                            break;
                        }
                        default: break;
                    }
                } else {
                    switch (id) {
                        case 'client': {
                            setShowClientAni(false);
                            break
                        }
                        case 'service': {
                            setShowServiceAni(false);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(false);
                            break;
                        }
                        default: break;
                    }
                }
            });
        });
        observer.observe(benefitRef.current);
        observer.observe(serviceRef.current);
        observer.observe(clientRef.current);
    }, []);

    return (
        <div className='services-analytics-section'>
            <section className='analytics-hero-section'>
                <Hero
                    heading={"Analytics"}
                    subHeading={"Equip yourself with the right analytical tools to build an insight-driven organization."}
                />
            </section>

            <SPBenefits initials={initials} overViewText={overViewText}>
                <div className={`a-benefits-container grid md:grid-cols-2 gap-10 pt-8`} id='benefit' ref={benefitRef}>
                    {Analytics_Benefits.map(benefit =>
                        <div key={benefit.id}
                            className='text-center md:px-16 relative analytic-benefit opacity-0'>
                            <img src={`/assets/services/Analytics/${benefit.src}`} alt={benefit.heading} className='mx-auto' />
                            <h3 className='sub-heading pb-2 text-center'>{benefit.heading}</h3>
                            <p className='sub-heading-content'>{benefit.content}</p>
                        </div>
                    )}
                </div>
            </SPBenefits>

            <SPServices initials={initials}>
                <div className={`${showServiceAni && 'fade'} a-services_container pt-12 grid grid-cols-1 grid-rows-3 md:grid-cols-2 md:grid-rows-2 justify-center text-white text-center md:px-12 md:gap-y-0 gap-y-4`}
                    id='service'
                    ref={serviceRef}
                >
                    <div className='business-intelligence px-12 md:border-r-4 md:border-b-4 md:border-dashed md:border-white pb-4'>
                        <img className="mx-auto h-[123px] object-contain" src={Analytics_Services[0]} alt="business-intelligence" />
                        <h1 className='p-2 sub-heading text-white'>Business intelligence</h1>
                        <p className='sub-heading-content pt-2'>
                            Our business intelligence tools and techniques provide you with a 360-degree analytical view of your data. You get insights from different data sources in a single click on your desktop.
                        </p>
                    </div>
                    <div className='data-visualization md:border-b-4 md:border-dashed md:border-white px-12 pb-4'>
                        <img className="mx-auto h-[123px] object-contain" src={Analytics_Services[1]} alt="Data Visualization" />
                        <h1 className='p-2 sub-heading text-white'>Data Visualization</h1>
                        <p className='sub-heading-content pt-2'>Our visualization services enable you to make sense of volumes of data by finding hidden patterns and trends. Paint a clear picture of your business growth and insights with our visualization services.
                        </p>
                    </div>
                    <div className='advanced-analytics md:px-10 md:col-span-2 md:px-0 px-12 mx-auto md:w-1/2 pt-6'>
                        <img className="mx-auto h-[123px] object-contain" src={Analytics_Services[2]} alt="Advanced Analytics" />
                        <h1 className='p-2 sub-heading text-white'>Advanced Analytics</h1>
                        <p className='sub-heading-content pt-2'>Optimize your business performance by analyzing and deriving insights from data. Our advanced analytics techniques include forecasting, visualization, and data mining.
                        </p>
                    </div>
                </div>
            </SPServices>

            <OurClientsSection>
                <div className={`${showClientAni && 'fadeInDown'}`} ref={clientRef} id='client'>
                    <div className='our-clients-container flex md:flex-row flex-col justify-evenly md:gap-y-10 pt-8'>
                        {Analytics_Clients.map((client, index) =>
                            <div className='a-client flex md:items-start items-center' key={index}>
                                <img src='/assets/services/clients.svg' alt={''} />
                                <div className='serive-content p-4'>
                                    <h3 className='client-heading sub-heading pb-5'>{client}</h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='a-client flex items-start justify-center'>
                        <img src='/assets/services/clients.svg' alt={''} />
                        <div className='serive-content p-4'>
                            <h3 className='client-heading sub-heading pb-5'>Global Organizations</h3>
                        </div>
                    </div>
                </div>
            </OurClientsSection>
        </div>
    )
}

export default Analytics