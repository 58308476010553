import axios from 'axios';

import {
    UPDATE_USER,
    DELETE_USER,
    FETCH_ALL_USER,
    FLASH_SUCCESS,
    FLASH_ERROR
} from '../constants/actionTypes';
import { apiUrl } from '../api';

const config = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
};

export const getUsers = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${apiUrl}/users/getUsers`, config)
        dispatch({ type: FETCH_ALL_USER, payload: data })
    }
    catch (err) {
        dispatch({ type: FLASH_ERROR, payload: err.message })
    }
}

export const updateUsers = (user) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${apiUrl}/users/updateUserApproval`, { user }, config)
        console.log("from backend: ", data)
        dispatch({ type: UPDATE_USER, payload: data.data })

        if (data.type === "success") {
            dispatch({ type: FLASH_SUCCESS, payload: data.message })
        }
        else
            dispatch({ type: FLASH_ERROR, payload: data.message })
    }
    catch (err) {
        dispatch({ type: FLASH_ERROR, payload: err.message })
    }
}

export const deleteUser = (user) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${apiUrl}/users/deleteUser`, { user }, config)
        dispatch({ type: DELETE_USER, payload: user._id })

        if (data.type === "success") {
            dispatch({ type: FLASH_SUCCESS, payload: data.message })
        }
        else
            dispatch({ type: FLASH_ERROR, payload: data.message })
    }
    catch (err) {
        dispatch({ type: FLASH_ERROR, payload: err.message })
    }
}
