import React from 'react'

const Spinner = () => {

    const spinnerStyles = {
        "width": "64px",
        "height": "64px",
        "border": "8px solid",
        "borderColor": "var(--theme-orange) transparent",
        "borderRadius": "50%",
        "animation": "spin-anim 1.2s linear infinite",
    }

    return (
        <div style={spinnerStyles}></div>
    )
}

export default Spinner