import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineClose } from 'react-icons/ai';

import { RESET_MESSAGE } from '../../constants/actionTypes'

import './flash.css';

function Flash() {
    const dispatch = useDispatch()
    const flash = useSelector(state => state.flash)

    const updateFlash = () => {
        dispatch({ type: RESET_MESSAGE, payload: null })
    }
    console.log(flash)
    useEffect(() => {

    }, [dispatch])
    return (
        <div className={`${flash.type ? 'sticky' : null}`}>
            {
                flash.type ?
                    <div className={`alert flash-${(flash.type === 'success') ? 'success' : 'error'} alert-dismissible m-0 show flash`} role="alert">
                        <div>{flash.message}</div>
                        <button type="button"
                            onClick={() => updateFlash()}
                            className="btn-close flash-btn"
                            data-bs-dismiss="alert" aria-label="Close">
                            <AiOutlineClose className='inline h-full' />
                        </button>
                    </div>
                    :
                    null
            }
        </div>
    )
};

export default Flash;