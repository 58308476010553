import React, { useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

const RichTextEditor = ({ initialValue, setBlogBody }) => {
    const editor = useRef(null);

    useEffect(() => {
        const joditDiv = document.getElementsByClassName('jodit-workplace')

        try {
            joditDiv.style.cssText = `
        height:1200px;
    `
        } catch (e) {
            console.log(e)

        }
    },)

    return (
        <JoditEditor
            ref={editor}
            value={initialValue}
            height="800"
            onChange={(newContent) => setBlogBody(newContent)}
        />
    );
};

export default RichTextEditor;
