import React, { useEffect, useRef, useState } from 'react'

import Hero from '../../components/Hero'
import OurClientsSection from '../../components/OurClientsSection'
import SPBenefits from '../../components/servicesComponents/SPBenefits'

import { Benefits, OurServices, VectorSvg } from '../../constants/servicesConstants'
import SPServices from '../../components/servicesComponents/SPServices'

const OperationsTransformation = () => {

    const initials = 'ot';
    const overViewText = 'Our impactful operations transformation solutions reimagine the inner workings of your organization to propel growth. By developing new capabilities and enhancing your current processes, we assist you in realizing the full potential of your operations.';
    const OT_Clients = ['Small & Mid-Size Organizations', 'Family-Owned Businesses'];

    const serviceRef = useRef(null);
    const [showServiceAni, setShowServiceAni] = useState(false);

    const clientRef = useRef(null);
    const [showClientAni, setShowClientAni] = useState(false);

    const benefitRef = useRef(null);
    const [showBenefitAni, setShowBenefitAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                let id = entry.target.id;
                if (entry.isIntersecting) {
                    switch (id) {
                        case 'service': {
                            setShowServiceAni(true);
                            break;
                        }
                        case 'client': {
                            setShowClientAni(true);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(true);
                            break;
                        }
                        default: break;
                    }
                } else {
                    switch (id) {
                        case 'client': {
                            setShowClientAni(false);
                            break
                        }
                        case 'service': {
                            setShowServiceAni(false);
                            break;
                        }
                        case 'benefit': {
                            setShowBenefitAni(false);
                            break;
                        }
                        default: break;
                    }
                }
            });
        });
        observer.observe(benefitRef.current);
        observer.observe(serviceRef.current);
        observer.observe(clientRef.current);
    }, []);

    return (
        <div className='ot-section'>
            <section className='ot-hero-section'>
                <Hero
                    heading={"Operations Transformation"}
                    subHeading={"Maximize Your Operational Capabilities"}
                    color={null}
                    svgImage={VectorSvg}
                />
            </section>
            <SPBenefits initials={initials} overViewText={overViewText}>
                <div ref={benefitRef} className={`${showBenefitAni && 'fade'} ot-benefits-container grid md:grid-cols-3 gap-x-20`} id='benefit'>
                    {Benefits.map(benefit =>
                        <div key={benefit.id}
                            className={`${benefit.id === 2 ? 'md:!mt-24 !mt-0' : ''} text-center pt-8`}>
                            <img src={benefit.src} alt={benefit.alt} className='mx-auto' />
                            <h3 className='sub-heading pb-4 text-center'>{benefit.heading}</h3>
                            <p className='sub-heading-content'>{benefit.content}</p>
                        </div>
                    )}
                </div>
            </SPBenefits>

            <SPServices initials={initials}>
                <div className='ot-our-services-container md:grid grid-cols-2 gap-y-6 pt-12' ref={serviceRef} id='service'>
                    {OurServices.map((service) =>
                        <div className={`${showServiceAni && 'ot-service'} flex md:flex-row flex-col items-start md:px-14 px-2 md:pb-8 pb-10 md:w-11/12 relative h-full text-center md:text-left opacity-0`} key={service.id}>
                            <img src={service.src} alt={service.alt} className='mx-auto md:m-0' />
                            <div className='ot-serive-content m-4 mb-0 h-full relative border-b border-solid border-white mt-0 md:mt-4'>
                                <h3 className='ot-service-heading sub-heading text-white py-2'>{service.heading}</h3>
                                <p className='ot-service-desc sub-heading-content md:p-0 pb-2'>{service.content}</p>
                                <span className='service-circle absolute -right-7 -bottom-3 rounded-full bg-theme-orange h-6 w-6 content-none inline-block'></span>
                            </div>
                        </div>
                    )}
                </div>
            </SPServices>

            <OurClientsSection>
                <div ref={clientRef} id='client' className={`${showClientAni && 'fadeInDown'} our-clients-container grid md:grid-cols-2 gap-y-10 pt-6`}>
                    {OT_Clients.map((client, idx) =>
                        <div className='ot-client flex items-center md:pl-24' key={idx}>
                            <img src='/assets/services/clients.svg' alt='' />
                            <div className='serive-content'>
                                <h3 className='client-heading sub-heading pb-1 pl-4'>{client}</h3>
                            </div>
                        </div>
                    )}
                </div>
            </OurClientsSection>
        </div>
    )
}

export default OperationsTransformation