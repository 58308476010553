import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Flash from './components/flash/Flash';
import Footer from './components/Footer';

import Home from './pages/Home';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import OperationsTransformation from './pages/services/OperationsTransformation';
import ProductDevelopment from './pages/services/ProductDevelopment';
import Analytics from './pages/services/Analytics';
import CloudTransformation from './pages/services/CloudTransformation';
import ContactUs from './pages/ContactUs';
import BlogNew from './pages/blog/Blogs';
import EditBlog from './pages/blog/EditBlog';
import CreateBlog from './pages/blog/CreateBlog';
import UserBlogs from './pages/blog/UserBlogs';
import BlogDetailNew from './pages/blog/BlogDetailNew';

import AdminUsers from './pages/admin/AdminUsers';
import AdminBlogs from './pages/admin/AdminBlogs';
import AdminContacts from './pages/admin/AdminContacts';

import { Authprovider } from './context/auth-context';
import { RequireAuth } from './utils/RequireAuth';

import './App.css';

const App = () => {
    return (
        <div className="App p-5 pt-2">
            <Router>
                <ScrollToTop />
                <Authprovider>
                    <Navbar />
                    <Flash />
                    <Routes>
                        <Route path='/' element={<Home />} exact />
                        <Route path='/register' element={<Register />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/resources/'>
                            <Route path='' element={<BlogNew />} />
                            <Route path='editBlogs/:blogId'
                                element={
                                    <RequireAuth>
                                        <EditBlog />
                                    </RequireAuth>
                                }
                            />
                            <Route path='createblog'
                                element={
                                    <RequireAuth>
                                        <CreateBlog />
                                    </RequireAuth>
                                }
                            />
                            <Route path='details/:blogId' element={<BlogDetailNew />} />
                        </Route>
                        <Route path='/userblogs'
                            element={
                                <RequireAuth>
                                    <UserBlogs />
                                </RequireAuth>
                            }
                        />
                        <Route path='/admin/'>
                            <Route path='users'
                                element={
                                    <RequireAuth>
                                        <AdminUsers />
                                    </RequireAuth>
                                }
                            />
                            <Route path='blogs'
                                element={
                                    <RequireAuth>
                                        <AdminBlogs />
                                    </RequireAuth>
                                }
                            />
                            <Route path='contacts'
                                element={
                                    <RequireAuth>
                                        <AdminContacts />
                                    </RequireAuth>
                                }
                            />
                        </Route>
                        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
                        <Route path='/services/'>
                            <Route path='operationsTransformation' element={<OperationsTransformation />} />
                            <Route path='productDevelopment' element={<ProductDevelopment />} />
                            <Route path='analytics' element={<Analytics />} />
                            <Route path='cloudTransformation' element={<CloudTransformation />} />
                        </Route>
                        <Route path='/contact' element={<ContactUs />} />
                    </Routes>
                    <Footer />
                </Authprovider>
            </Router>
        </div>
    );
}

export default App;
