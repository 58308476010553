import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Spinner from '../../components/Spinner'

import { deleteUser, getUsers, updateUsers } from '../../actions/users.js'

import './adminUsers.css'

function AdminUsers() {
    const dispatch = useDispatch()
    const users = useSelector((state) => state.users)

    const updateAprroval = (user) => {
        dispatch(updateUsers(user))
    }

    const userDelete = (user) => {
        dispatch(deleteUser(user))
    }

    useEffect(() => {
        dispatch(getUsers())
    }, [dispatch])

    return (
        <div className='adminuserscontainer'>
            <div className='w-full m-0 flex justify-center' >
                <div className='w-1/12 users-headings'>
                    S.No.
                </div>
                <div className='w-1/4 users-headings'>
                    Name
                </div>
                <div className='w-1/6 users-headings'>
                    Status
                </div>
                <div className='w-1/6 users-headings'>
                    Approve
                </div>
                <div className='w-1/6 users-headings' style={{ borderRight: "0" }}>
                    Delete
                </div>
            </div>
            {
                users.length ?
                    users.slice(0).reverse().map((user, index) => {
                        if (user.username !== 'admin')
                            return (
                                <div key={index} className='flex w-full mx-0 flex justify-center'>
                                    <div className='w-1/12 users-info'>
                                        {index + 1}
                                    </div>
                                    <div className='w-1/4 users-info'>
                                        {user.username}
                                    </div>
                                    <div className='w-1/6 users-info'>
                                        {
                                            user.approved ?
                                                'Approved' : "Not Approved"
                                        }
                                    </div>
                                    <div className='w-1/6 users-info'>
                                        <button className={`user-btn ${user.approved ? 'ngt' : 'pst'}-btn`} onClick={() => updateAprroval(user)}>{`${user.approved ? 'Unapprove' : 'Approve'}`}</button>
                                    </div>
                                    <div className='w-1/6 users-info' style={{ borderRight: "0" }}>
                                        <button className='user-btn ngt-btn' onClick={() => userDelete(user)}>Delete</button>
                                    </div>
                                </div>
                            )
                    }) : <div className='flex justify-center al my-5' style={{ height: `662.26px`, paddingTop: "250px" }}><Spinner /></div>
            }
        </div>
    )
}

export default AdminUsers