import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';

import { WhatWeDo, WhyChooseUs } from '../constants/HomePageContants'
// import { CarouselImages } from '../constants/ImageConstant'

const Home = () => {

    const whyRef = useRef(null);
    const [showAni, setShowAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setShowAni(entry.isIntersecting);
        });
        observer.observe(whyRef.current);
    }, []);

    return (
        <div className='home-container'>

            <div className='grid md:grid-cols-2 p-20 items-center hero-section h-80 md:px-20 pl-4 mb-4'>
                <div className={`hero-left text-white fadeInDown`}>
                    <h1 className='hero-heading hero-text uppercase text-white pb-4 md:!text-[28px] !text-[21px]'>
                        VISUALIZE | AUTOMATE |
                        <br />
                        INNOVATE
                    </h1>
                    <Link to={'/contact'}>
                        <button className='get-in-touch-btn px-12 py-2 md:mt-4 !bg-[#5F5F60] rounded-3xl'>
                            Get In Touch
                        </button>
                    </Link>
                </div>
            </div>

            <section className='what-we-do-section px-8 lg:px-28 py-9'>
                <div className='text-center'>
                    <h1 className='section-heading crumb'>What We Do</h1>
                </div>
                <div className='what-we-do-container grid md:grid-cols-2 py-4'>
                    {WhatWeDo.map(service =>
                        <div key={service.id} className='single-service relative flex pt-10 gap-x-2 opacity-0 flex-col md:flex-row' id={service.id}>
                            <img
                                className='self-start mx-auto md:m-0'
                                src={service.image.src}
                                alt={service.image.alt}
                            />
                            <div className='service-content flex flex-col'>
                                <h6 className='sub-heading p-1 border-b-2 border-solid border-theme-d9 whitespace-nowrap text-theme-blue text-center md:text-left'>{service.heading}</h6>
                                <p className='sub-heading-content bg-theme-bg h-full mt-2 flex-1 p-2 md:w-[40ch]'>{service.content}</p>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <section className='why-choose-us-section px-12 pb-10 text-center'>
                <h1 className='crumb section-heading mt-3'>Why Us</h1>
                <div ref={whyRef} className={`benefits grid md:grid-cols-3 py-5 ${showAni && 'fadeInDown'}`}>
                    {WhyChooseUs.map(benefit =>
                        <div key={benefit.id} className='single-benefit text-center pt-8 md:px-10 md:pt-4'>
                            <img
                                className='text-center mx-auto'
                                src={benefit.image.src} alt={benefit.image.alt} />
                            <h6 className='text-theme-blue sub-heading pt-2 pb-4'>{benefit.heading}</h6>
                            <p className='sub-heading-content wcu'>{benefit.content}</p>
                        </div>
                    )}
                </div>
            </section>

        </div>
    )
}

export default Home